import React, {
  FC,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { useFormState } from 'react-final-form';
import { FormField } from '@/components/ui/FormElements/FormField';
import { Select } from '@/components/ui/FormElements/FormInputs/Select';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useDomains } from '@/controllers/subDomain/subDomain.hooks/useDomains';
import { i18nIsGMDomain } from '@/middleware/i18n/i18n.utils/i18n.isGMDomain';

interface Props {
  domain: string;
  setDomain: Dispatch<SetStateAction<string>>;
  fieldName?: string;
}

export const LocationSelector: FC<Props> = (props) => {
  const { domain, setDomain, fieldName = 'location' } = props;
  const { t } = useTranslation([I18N_CODES.countrySelector]);
  const [domains] = useDomains();

  const { submitting } = useFormState();

  const options = useMemo(() => (
    domains
      .filter((item) => !i18nIsGMDomain(item.slug))
      .map((item) => ({
        value: item.slug,
        label: item.countryName as string,
      }))
  ), [domains]);

  const initialValue = useMemo(() => (
    options.find((item) => (
      item.value === domain
    ))
  ), [options, domain]);

  return (
    <FormField
      label={{
        for: 'location-selector',
        text: t(`${I18N_CODES.countrySelector}:country_selector_location_input`),
      }}
      renderInput={(inputProps) => (
        <Select
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 16 }),
            menu: (provided) => ({ ...provided, zIndex: 16 }),
          }}
          menuPosition="fixed"
          isSearchable={false}
          config={{
            validate: (option) => {
              if (option?.value) {
                setDomain(option.value);
              }
            },
            initialValue,
          }}
          options={options}
          {...inputProps}
        />
      )}
      disabled={submitting}
      name={fieldName}
    />
  );
};
