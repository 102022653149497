import { useCallback } from 'react';
import getConfig from 'next/config';
import { DEFAULT_LOCALE } from '@/middleware/i18n/i18n.config';
import { LanguageName } from '../graphql/generated';
import { sortLanguages } from './language.helpers';

export const useDomainLanguage = () => {
  const { publicRuntimeConfig = {} } = getConfig() || {};

  return useCallback(
    (domain: string, lang?: LanguageName) => {
      const domainLocaleMap = publicRuntimeConfig.DYNAMIC_I18N_CONFIG
        ?.domainLocaleMap;

      const sortedDomainLocaleMap = sortLanguages(domainLocaleMap);

      const matchedByLang = lang ?? sortedDomainLocaleMap
        ?.[domain]
        ?.find((locale: string) => locale === lang);

      return matchedByLang
        || sortedDomainLocaleMap?.[domain]?.[0]
        || DEFAULT_LOCALE;
    },
    [publicRuntimeConfig],
  );
};
